<template>
<v-app>
  <div class="alert alert-success" v-if="sent">
    <strong>{{ $t('Ihre Anfrage wurde abgeschickt.') }}</strong>
  </div>

  <v-form @submit="submit" v-if="!sent">
    <div class="alert alert-danger" v-if="error">
      <strong>{{ $t('Es ist ein Fehler aufgetreten.') }}</strong>
    </div>

    <div class="alert alert-danger" v-if="userError">
      <strong>{{ $t('Bitte prüfen Sie Ihre Eingaben.') }}</strong>
    </div>

    <v-text-field
      label="E-Mail"
      v-model="email"
      prepend-icon="fa-user"
      outlined
      type="email"
      required
    ></v-text-field>

    <button type="submit" class="btn btn-primary">Anmelden</button>
</v-form>
</v-app>
</template>

<script>
export default {
  name: "LoginApp",

  components: {
  },

  data() {
    return {
      sent: false,
      userError: false,
      error: false,
      email: null
    };
  },

  methods: {
    submit(e) {
      e.preventDefault();

      window.depsloader("axios", (axios) => {

        axios({
          url: "/wp-json/yeti-login/v1/login",
          method: "post",
          data: {
            email: this.email
          }
        }).then(res => {
          this.sent = true;
        }).catch(err => {
          this.error = true;
        });
      });
    }
  }
};
</script>
