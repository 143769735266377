<template>
<a href="javascript:void(0);" @click="close()" class="header__dropdown-close">Zurück</a>
</template>

<script>
export default {
  name: "DropdownClosebtn",

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    close() {
      this.$parent.close();
    }
  }
};
</script>
