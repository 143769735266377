<template>
<div v-show="show" class="dropdown">
    <transition name="slide-fade">
    <div ref="dropdown" :class="{ 'is-touch': isTouchDevice }">
        <slot></slot>
    </div>
    </transition>
</div>
</template>

<script>
export default {
  name: "DropdownNavigation",

  data() {
    return {
      isTouchDevice: false,
      show: false,
      init: false,
      closeTimeout: null,
      dropdownTitle: null,
      currentParent: null,
      dropdownLinks: []
    };
  },

  computed: {
    load() {
      if (this.show) {
        return true;
      }

      if (this.init) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.show) {
      this.init = true;
    }

    const list = document.querySelectorAll("#menu-hauptnavigation > li.menu-item-has-children");
    list.forEach((elm) => {
      var linkElms = elm.querySelectorAll("ul > li > a");
      const linkElm = elm.querySelector("a");
      linkElm.onclick = (e) => {
        e.preventDefault();

        if (linkElms.length > 1) {
          if (linkElm.classList.contains("sw-dropdown-open")) {
            this.close();
            return;
          }

          if (this.show && this.currentParent) {
            this.currentParent.classList.remove("sw-dropdown-open");
            this.currentParent = null;
          }

          this.dropdownLinks = [];
          const firstItem = {
            text: linkElm.innerText,
            href: linkElm.getAttribute("href"),
            target: linkElm.getAttribute("target"),
            active: elm.className.indexOf("current-menu-item") != -1
          };

          this.dropdownTitle = firstItem;
          this.currentParent = linkElm;

          Array.from(linkElms).forEach((a, k) => {
            if (k == 0) {
              return;
            }

            let icon = [...a.parentNode.classList].reduce(function(icon, cl) {
              if (cl && cl.indexOf("icon--") === 0) {
                return cl;
              }

              if (icon && icon.indexOf("icon--") === 0) {
                return icon;
              }

              return null;
            });

            if (icon) {
              icon = icon.replace("icon--", "fa-") + " far fa-fw";
            }

            const item = {
              icon: icon,
              text: a.innerText,
              href: a.getAttribute("href"),
              target: a.getAttribute("target"),
              active: a.parentNode.className.indexOf("current-menu-item") != -1
            };

            this.dropdownLinks.push(item);
          });

          this.triggerShow();
        } else {
          this.debug("mouseover, close");
          this.close();
        }
      };
    });

    this.clickEvent = (e) => {
      this.onClick(e);
    };
  },

  methods: {
    debug(m) { // eslint-disable-line
      console.log(m);
    },

    getDropdownElement() {
      this.dropdownElement = document.querySelector("div.header__dropdown");
      if (!this.dropdownElement) {
        // console.log("header__dropdown not found");
      }

      return this.dropdownElement;
    },

    onClick(e) {
      const elm = this.getDropdownElement();
      const y = elm.offsetTop + elm.offsetHeight;

      if (e.clientY - y > 50 && window.innerWidth > 992) {
        this.debug("click close");
        this.close();
      }
    },

    triggerShow() {
      if (this.$parent.isDropdownDisabled()) {
        return;
      }

      if (this.closeTimeout) {
        this.debug("clear timeout");
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }

      if (!this.show) {
        window.addEventListener("click", this.clickEvent);
      }

      this.show = true;
      this.currentParent.classList.add("sw-dropdown-open");
    },

    close() {
      if (this.show) {
        window.removeEventListener("click", this.clickEvent);
      }

      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }

      this.show = false;
      this.currentParent.classList.remove("sw-dropdown-open");
    },

    triggerClose() {
      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
        return;
      }

      this.closeTimeout = setTimeout(() => {
        this.debug("closeTimeout()");
        this.close();
      }, 500);
    }
  }
};
</script>
<style >
</style>
