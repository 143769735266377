<template>
<div class="testimonial__listing">
  <div class="testimonial__listing__anker" ref="listingAnker"></div>
  <div>
    <div v-for="testimonial in testimonials"
        :key="testimonial.id"
        v-html="testimonial.html"
        class="testimonials-archive__wrap">
    </div>
  </div>

  <div v-if="pages && pages > 1" class="pagination text-center mb-4 mt-2">
    <a class="prev page-numbers"
      @click.prevent="prev"><span class="fal fa-chevron-left"></span></a>

    <a class="page-numbers"
      v-for="p in pages"
      :key="p"
      @click.prevent="open(p);"
      v-bind:class="{ current: p == activePage }">
      {{ p }}
    </a>

    <a class="next page-numbers"
      @click.prevent="next"><span class="fal fa-chevron-right"></span></a>
  </div>
</div>
</template>

<script>
export default {
  name: "Archive",

  components: {},

  data() {
    return {
      testimonials: [],
      page: 1,
      pages: null,
      activePage: null,
      firstRun: true
    }
  },

  props: [
    "ignorePosts"
  ],

  computed: {
    scrollTopPosition() {
      return this.$refs.listingAnker.parentNode.offsetTop + this.$refs.listingAnker.offsetTop;
    }
  },

  methods: {
    load() {
      if (this.page == this.activePage) {
        return;
      }

      let params = this.getParams();

      window.depsloader(["axios"], (axios) => {
        axios.get("/wp-json/rest/testimonials/v1/archive", {
            params: params
        }).then((response) => {
          this.page = response.data.page;
          this.activePage = response.data.page;
          this.pages = response.data.pages;
          this.testimonials = response.data.entries;

          this.$nextTick(() => {
            this.$yeti.readmore();
            if (!this.firstRun) {
              this.scrollToTop();
              var searchParameters = new URLSearchParams();
              Object.keys(params).forEach((parameterName) => {
                if (this.$options._propKeys.indexOf(parameterName) == -1) {
                  searchParameters.append(parameterName, params[parameterName]);
                }
              });

              window.location.hash = searchParameters;
            }

            this.firstRun = false;
          });
        });
      });
    },

    getParams() {
      return {
        page: this.page,
        ignorePosts:this.ignorePosts
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: this.scrollTopPosition,
        left: 0,
        behavior: 'smooth'
      });
    },

    open(p) {
      this.page = p;
      this.load();
    },

    prev() {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      }

      this.load();
    },

    next() {
      this.page++;
      if (this.pages && this.page > this.pages) {
        this.page = this.pages;
      }

      this.load();
    },
  },

  mounted() {
    (new URLSearchParams(window.location.hash.replace("#", ""))).forEach((value, key) => {
      if (typeof this[key] !== "undefined" && value != "") {
        if (typeof this[key] == "object") {
          this[key] = value.split(",").map(id => parseInt(id)).filter((v, i, a) => a.indexOf(v) === i);
        } else if (typeof this[key] == "number") {
          this[key] = parseInt(value);
        }
      }
    });

    this.load();
  }
}
</script>
