export default {
  async asyncImport(name) {
    if (name === "swiper") {
      return import(/* webpackChunkName: "swiper" */ "./swiper.js");
    }

    if (name == "axios") {
      return import(/* webpackChunkName: "axios" */ "./axios.js");
    }

    if (name == "vue-filters") {
      return import(/* webpackChunkName: "vue-filters" */ "./vue-filters");
    }

    if (name == "vue-i18n") {
      return import(/* webpackChunkName: "vue-i18n" */ "./vue-i18n");
    }

    if (name == "leaflet") {
      return import(/* webpackChunkName: "leaflet" */ "./leaflet");
    }

    if (name == "lightgallery") {
      return import(/* webpackChunkName: "lightgallery" */ "./lightgallery");
    }

    if (name == "js-cookie") {
      return import(/* webpackChunkName: "js-cookie" */ "./js-cookie");
    }

    if (name == "iframe-resizer") {
      return import(/* webpackChunkName: "iframe-resizer" */ "./iframe-resizer");
    }

    if (name == "vuetify-form") {
      return import(/* webpackChunkName: "vuetify-form" */ "./vuetify-form");
    }

    if (name == "vuetify-accordion") {
      return import(/* webpackChunkName: "vuetify-accordion" */ "./vuetify-accordion");
    }

    if (name == "vuetify-tabs") {
      return import(/* webpackChunkName: "vuetify-tabs" */ "./vuetify-tabs");
    }

    if (name == "counter-app") {
      return import(/* webpackChunkName: "counter-app" */ "../components/counter-app");
    }

    if (name == "listing") {
      return import(/* webpackChunkName: "listing" */ "../components/listing");
    }

    console.log("depsloader: " + name + " does not exist");

    return null;
  },

  async load(name) {
    const res = await this.asyncImport(name);
    return res.default;
  }
};
