<template>
<div class="immoapp__search" data="app">
  <v-form @submit.prevent="search">
    <div class="row row-small">
      <div class="col-24 mb-1 col-md-4">
        <v-select
          :items="marketingTypes"
          label="Kauf & Miete"
          v-model="marketingType"
          filled
          flat
          clearable
          attach
          hide-details
        ></v-select>
      </div>

      <div class="col-24 mb-1 col-md-5">
        <v-select
          :items="categories"
          v-model="category"
          label="Kategorie"
          :return-object="true"
          filled
          flat
          clearable
          attach
          hide-details
        ></v-select>
      </div>

      <div class="col-24 mb-1 col-md-8">
        <geocoder-autocomplete
          v-model="addressModel"
          @change="updateGeo"></geocoder-autocomplete>
      </div>

      <div class="col-24 mb-1 col-md-4">
        <v-select
          :items="radiusList"
          label="Umkreis"
          v-model="radius"
          attach
          filled
          flat
          clearable
          hide-details
        ></v-select>
      </div>

      <div class="col-24 mb-1 col-md-3">
        <button type="submit" class="btn btn-block btn-primary">Suchen</button>
      </div>
    </div>
  </v-form>
</div>
</template>

<script>
import GeocoderAutocomplete from "./GeocoderAutocomplete";

export default {
  name: "SearchApp",

  props: [
    "categories",
    "params",
    "action"
  ],

  components: {
    GeocoderAutocomplete,
  },

  data: () => ({
    marketingType: "",
    category: "",
    radius: null,
    address: "",
    addressModel: "",
    lat: null,
    lng: null,
    sort: null,
    marketingTypes: [
      {
        text: 'Kauf',
        value: "buy"
      },
      {
        text: 'Miete',
        value: "rent"
      }
    ],
    radiusList: [
      {
        "text": "5 km",
        "value": 5
      },
      {
        "text": "10 km",
        "value": 10
      },
      {
        "text": "15 km",
        "value": 15
      },
      {
        "text": "25 km",
        "value": 25
      },
      {
        "text": "35 km",
        "value": 35
      }
    ]
  }),

  computed: {
  },

  mounted() {
    Object.keys(this.params).forEach(k => {
      if (this.params[k].value) {
        this[k] = this.params[k].value;
      }

      if (k == "categoryId") {
        for (let i in this.categories) {
          if (this.categories[i].categoryId == this.categoryId) {
            this.category = this.categories[i];
          }
        }
      }
    });

    this.addressModel = {
      text: this.address,
      value: this.address,
      lat: this.lat,
      lng: this.lng
    };
  },

  methods: {
    updateGeo(geo) {
      if (geo)  {
        this.lat = geo.lat;
        this.lng = geo.lng;
        this.address = geo.text;

        if (!this.radius) {
          this.radius = this.radiusList[0].value;
        }
      } else {
        this.lat = null;
        this.lng = null;
        this.address = null;
        this.radius = null;
      }
    },

    search() {
      const params = new URLSearchParams();

      if (this.category) {
        this.categoryId = this.category.categoryId;
      } else {
        this.categoryId = null;
      }

      Object.keys(this.params).forEach(k => {
        if (k != "paged") {
          if (this[k] || this[k] === "") {
            if (k == "sort") {
              params.append(this.params[k].name, this[k].field + "|" + this[k].order.toLowerCase());
            } else {
              params.append(this.params[k].name, this[k]);
            }
          }
        }
      });

      let action = this.action;

      window.location.href = action + "?" + params.toString();
    }
  }
};
</script>
