<template>
<div>
    <slot></slot>
</div>
</template>

<script>
export default {
  name: "Mainnavigation",

  mounted() {
    this.navbarToggler = document.querySelector("div.sw-menu-icon");
    if (this.navbarToggler) {
      this.navbarToggler.onclick = (e) => {
        e.preventDefault();

        let elm = document.querySelector(this.navbarToggler.getAttribute("data-collapse"));
        elm.classList.toggle("show");
      }
    }
  },

  methods: {
    isDropdownDisabled() {
      return false;
    }
  }
};
</script>
