import Vue from "vue";
import Archive from "./components/Archive.vue";

const element = document.querySelector("#yeti-module-testimonial-archive");
if (element) {
  new Vue({
    el: element,
    template: element,
    components: {
      Archive
    }
  });
} else {
  console.log("testimonials: #yeti-module-testimonial-archive not found");
}
