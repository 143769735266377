/*
Cookie Banner wird in die app.js geschrieben, d.h. keine großen Komponenten hier direkt includen.

Auf keinen Fall vuetify-Komponenten hier verwenden!
*/

import Vue from "vue";
import Mainnavigation from "./components/Mainnavigation.vue";
import DropdownNavigation from "./components/DropdownNavigation.vue";
import DropdownTitle from "./components/DropdownTitle.vue";
import DropdownLinks from "./components/DropdownLinks.vue";
import DropdownClosebtn from "./components/DropdownClosebtn.vue";

const element = document.querySelector("#yeti-module-mainnavigation");
if (element) {
  new Vue({
    el: element,
    template: element,
    components: {
      Mainnavigation,
      DropdownNavigation,
      DropdownTitle,
      DropdownLinks,
      DropdownClosebtn
    }
  });
} else {
  console.log("mainnavigation: #yeti-module-mainnavigation not found");
}
