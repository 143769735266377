<template>
<div ref="cookieNotice" :class="{ 'cookie-notice__relative': fixed }">
<transition name="fade">
  <div class="cookie-notice" v-if="show">
  <div class="container text-small">
    <explorer-warning></explorer-warning>
    <a @click.prevent="decline" class="cookie-notice-close" :title="$t('ablehnen')"><i class="far fa-times"></i></a>
    <p>
      {{ $t('Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies.') }}<br />
      {{ $t('Folgende Tools werden eingesetzt:') }}
    </p>

    <ul v-if="tools">
      <li v-for="t in tools" :key="t">{{ t }}</li>
    </ul>
    <p>
      <cookie-notice-link class="btn btn-block btn-primary">{{ $t('Ich akzeptiere') }}</cookie-notice-link>
    </p>

    <p>{{ $t('Wenn Sie der Verwendung von Cookies nicht zu stimmen werden nur technisch notwendige Cookies gesetzt.') }}
      <strong>{{ $t('Weitere Informationen zu Cookies und die Möglichkeit, Ihre Einwilligung jederzeit zu widerrufen erhalten Sie in unserer') }}
      <a :href="this.privacyUrl" target="_blank">{{ $t('Datenschutzerklärung') }}</a>.</strong>
    </p>
  </div>
  </div>
</transition>
</div>
</template>

<script>
import ExplorerWarning from "./ExplorerWarning";
import CookieNoticeLink from "./CookieNoticeLink";
import tracking from "../tracking";
import "./CookieNotice.scss";

let fixedTimeout;
let footerElm = null;
let cookieNoticeElm = null;

window.gaOptout = function() {
  const config = tracking.getConfig();

  tracking.doDecline();

  tracking.saveConfig(config);

  alert("Die Funktion wurde deaktiviert.");
};

export default {
  name: "CookieNotice",

  props: [
    "privacyUrl", "googleAnalyticsUserId", "googleTagManagerId",
    "facebookPixelId", "googleAdsConversionId", "googleAdsConversionLabel"
  ],

  components: {
    ExplorerWarning,
    CookieNoticeLink
  },

  data() {
    return {
      debug: true,
      loaded: false,
      show: false,
      fixed: false
    }
  },

  computed: {
    tools() {
      const list = [];

      if (this.googleAnalyticsUserId) {
        list.push("Google Analytics");
      }

      if (this.googleTagManagerId) {
        list.push("Google Tag Manager");
      }

      if (this.googleAdsConversionId) {
        list.push("Google Ads Conversion Tracking");
      }

      if (this.facebookPixelId) {
        list.push("Facebook Pixel");
      }

      return list;
    }
  },

  mounted() {
    tracking.debug = this.debug;
    tracking.googleAnalyticsUserId = this.googleAnalyticsUserId;
    tracking.googleTagManagerId = this.googleTagManagerId;
    tracking.googleAdsConversionId = this.googleAdsConversionId;
    tracking.googleAdsConversionLabel = this.googleAdsConversionLabel;
    tracking.facebookPixelId = this.facebookPixelId;

    tracking.init();

    const state = tracking.state();
    if (state == "accepted") {
      this.hide();
    } else if (state == "declined") {
      this.hide();
    } else {
      this.load();
    }
  },

  methods: {
    load() {
      if (this.loaded) {
        this.show = true;
        return;
      }

      this.loaded = true;
      this.show = true;

      this.observer = new IntersectionObserver(changes => {
          this.triggerChanges(changes);
      }, {});

      footerElm = document.querySelector("footer");
      cookieNoticeElm = this.$refs.cookieNotice;

      this.observer.observe(footerElm);
      this.observer.observe(cookieNoticeElm);
    },

    accept() {
      this.hide();
      tracking.doAccept();
    },

    decline() {
      this.hide();
      tracking.doDecline();
    },

    triggerChanges(changes) {
      const change = changes[0];

      if (change.target == footerElm && !this.fixed) {
        if (change.intersectionRatio > 0.0) {
          this.fixed = true;
        }
      } else if (change.target == cookieNoticeElm && this.fixed) {
        if (change.intersectionRatio > 0) {
          if (fixedTimeout) {
            clearTimeout(fixedTimeout);
          }
          fixedTimeout = setTimeout(() => {
            this.fixed = false;
          }, 500);
        }
      }
    },

    hide() {
      this.show = false;

      const elm = document.querySelector("#resetCookieNotice");
      if (elm) {
        elm.style.display = "";
        elm.onclick = () => {
          this.load();

          tracking.clearConfig();

          elm.style.display = "none";
        };
      }
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
