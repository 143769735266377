class Geocoder {
  constructor(axios) {
    this.axios = axios;
    this.axiosRequest = null;
  }

  cancelAutocomplete() {
    if (this.axiosRequest != null) {
      this.axiosRequest.source.cancel();
    }
  }

  _geoaddressText(g) {
    let s = "";
    if (g.zip && g.city && g.district) {
      s = g.zip + " " + g.city + "–" + g.district;
    } else if (g.zip && g.city) {
      s = g.zip + " " + g.city;
    } else if (g.city && g.district) {
      s = g.city + "–" + g.district;
    } else if (g.city) {
      s = g.city;
    }

    const p = [];

    if (g.street && g.number) {
      p.push(g.street + " " + g.number);
    } else if (g.street) {
      p.push(g.street);
    }

    if (s) {
      p.push(s);
    }

    if (g.state) {
      p.push(g.state);
    }

    if (g.country) {
      p.push(g.country);
    }

    return p.join(", ");
  }

  _geoaddress(l) {
    const geoaddress = {};

    const exists = (arr, obj) => {
      for (let i = 0; i < arr.length; i++) {
        if (!(arr[i] in obj)) {
          return false;
        }
        if (!obj[arr[i]]) {
          return false;
        }
      }

      return true;
    };

    // if (l.properties.osm_key != "place"
    // && l.properties.osm_key != "boundary"
    // && l.properties.osm_key != "office"
    // && l.properties.osm_key != "city"
    // && l.properties.osm_key != "waterway") {
    //   return null;
    // }


    if (l.properties.osm_key == "highway") {
      l.properties.street = l.properties.name;
      l.properties.name = null;
    }

    if (exists(["postcode", "city", "name"], l.properties)) {
      geoaddress["zip"] = l.properties.postcode;
      if (l.properties.city == l.properties.name) {
        geoaddress["city"] = l.properties.city;
      } else {
        geoaddress["city"] = l.properties.city;

        let district = false;
        if (l.properties.osm_key == "boundary") {
          district = true;
        } else if (l.properties.osm_key == "place" && l.properties.osm_value == "suburb") {
          district = true;
        }

        if (district) {
          geoaddress["district"] = l.properties.name;
        }
      }
    } else if (exists(["postcode", "city"], l.properties)) {
      geoaddress["zip"] = l.properties.postcode;
      geoaddress["city"] = l.properties.city;
    } else if (exists(["postcode", "name"], l.properties)) {
      geoaddress["zip"] = l.properties.postcode;
      geoaddress["city"] = l.properties.name;
    } else {
      geoaddress["city"] = l.properties.name;
    }

    geoaddress["lat"] = l.geometry.coordinates[1];
    geoaddress["lng"] = l.geometry.coordinates[0];

    geoaddress["state"] = l.properties.state;
    geoaddress["country"] = l.properties.country;
    if (geoaddress["country"] !== "Deutschland") {
      return null;
    }

    geoaddress["text"] = this._geoaddressText(geoaddress);

    return geoaddress;
  }

  geocode(params) {
    return new Promise((resolve, reject) => {
      this.axios.get("https://apps.screenwork.de/api/geocoder", {
        cancelToken: this.axiosRequest.source.token,
        params
      }).then(res => {
        const results = [];

        if (res.data.features) {
          res.data.features.forEach(l => {
            const geoaddress = this._geoaddress(l);
            if (geoaddress) {
              results.push(geoaddress);
            }
          });
        }

        resolve(results);
      }).catch((thrown) => {
        if (!this.axios.isCancel(thrown)) {
          console.log(thrown);
          reject(thrown);
        }
      }).finally(() => {
        this.axiosRequest = null;
      });
    });
  }

  autocomplete(q) {
    const CancelToken = this.axios.CancelToken;
    this.axiosRequest = {
      source: CancelToken.source()
    };

    const params = new URLSearchParams();
    params.append("q", q);
    params.append("lang", "de");
    params.append("limit", 20);
    params.append("osm_tag", "!place:state");
    params.append("osm_tag", "!place:country");
    params.append("osm_tag", "!place:house");
    params.append("osm_tag", "!boundary:protected_area");
    params.append("osm_tag", "place:city");
    params.append("osm_tag", "place:town");
    params.append("osm_tag", "boundary:administrative");

    return this.geocode(params);
  }

  reverse(lat, lng) {
    return new Promise((resolve, reject) => {
      this.axios.get("https://apps.screenwork.de/api/geocoder/reverse", {
        params: {
          lat: lat,
          lon: lng,
          lang: "de"
        }

      }).then(res => {
        if (res.data && res.data.features && res.data.features.length > 0) {
          const geoaddress = this._geoaddress(res.data.features[0]);
          resolve(geoaddress);
        }
        reject(new Error("invalid data"));
      }).catch(err => {
        reject(err);
      });
    });
  }
}

function GeocoderFactory() {
  return new Promise(resolve => {
    window.depsloader("axios", (axios) => {
      resolve(new Geocoder(axios));
    })
  });
}

export { GeocoderFactory };

export default {};
