// ist in main.js

export default {
  _config: null,
  debug:false,
  googleAnalyticsUserId: null,
  googleTagManagerId: null,
  googleAdsConversionId: null,
  googleAdsConversionLabel: null,
  facebookPixelId: null,
  gtagActions: [],

  getConfig() {
    if (this._config != null) {
      return this._config;
    }

    let data = window.localStorage.getItem("cookieNotice");
    if (data) {
      try {
        this._config = JSON.parse(data);
        return this._config;
      } catch(e) {
        // es-lint-disable
      }
    }

    return {
      accepted: false,
      declined: false
    }
  },

  saveConfig(config) {
    window.localStorage.setItem("cookieNotice", JSON.stringify(config));
    this._config = config;
  },

  clearConfig() {
    window.localStorage.removeItem("cookieNotice");
  },

  state() {
    const config = this.getConfig();

    if (config.accepted) {
      return "accepted";
    } else if (config.declined) {
      return "declined";
    }

    return null;
  },

  init() {
    const config = this.getConfig();

    const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

    const campaign = urlSearchParams.get("campaign");
    const keyword = urlSearchParams.get("keyword");

    if (campaign || keyword) {
      if (this.debug) {
        console.log("tracking: campaign=" + campaign + ", keyword=" + keyword);
      }
      config.campaignTracking = {
        campaign,
        keyword
      };

      this.saveConfig(config);
    }

    if (config.accepted) {
      this.track();
    }
  },

  googleAnalytics(userId) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://www.google-analytics.com/analytics.js";
    document.getElementsByTagName("head")[0].appendChild(s);

    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    window.ga("create", userId, "auto");
    window.ga("set", "anonymizeIp", !0);
    window.ga("send", "pageview");
  },

  google() {
    let tagId = this.googleTagManagerId;

    if (!tagId) {
      tagId = this.googleAnalyticsUserId;
    }

    if (!tagId) {
      tagId = this.googleAdsConversionId;
    }

    if (!tagId) {
      return;
    }

    if (this.debug) {
      console.log("tracking: google, tagId=" + tagId);
    }

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://www.googletagmanager.com/gtag/js?id=" + tagId;
    document.getElementsByTagName("head")[0].appendChild(s);

    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function gtag() { dataLayer.push(arguments); }
    window.gtag("js", new Date());
    window.gtag("config", tagId);

    if (this.googleAnalyticsUserId) {
      window.gtag("config", this.googleAnalyticsUserId, { "anonymize_ip": true });

      if (this.debug) {
        console.log("tracking: googleAnalyticsUserId=" + this.googleAnalyticsUserId);
      }
    }

    if (this.googleAdsConversionId) {
      window.gtag("config", this.googleAdsConversionId, { "anonymize_ip": true });

      if (this.debug) {
        console.log("tracking: googleAdsConversionId=" + this.googleAdsConversionId);
      }
    }

    this.parseTrackingElements();
    this.flushActions();
  },

  facebookPixel() {
    if (this.debug) {
      console.log("tracking: facebookPixelId=" + this.facebookPixelId);
    }

    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', this.facebookPixelId);
    fbq('track', 'PageView');
  },

  parseTrackingElements() {
    const list = document.getElementsByClassName("yeti-tracking-event");
    if (list == null) {
      return;
    }

    list.forEach((elm) => {
      const ds = elm.dataset;

      if ("event" in ds) {
        this.trackingEvent(ds.event, ds.label);
      }
    });
  },

  flushActions() {
    if ("gtag" in window) {
      this.gtagActions.forEach(cb => {
        cb(window.gtag);
      });

      this.gtagActions = [];
    }
  },

  doAccept() {
    const config = this.getConfig();

    config.accepted = true;
    config.declined = false;

    this.saveConfig(config);

    this.track();
  },

  doDecline() {
    const config = this.getConfig();

    config.accepted = false;
    config.declined = true;

    this.saveConfig(config);
  },

  track() {
    if (navigator.userAgent.indexOf("Page Speed Insights") > -1) {
      return;
    }

    if (this.googleAnalyticsUserId || this.googleTagManagerId || this.googleAdsConversionId) {
      this.google();
    }

    if (this.facebookPixelId) {
      this.facebookPixel(this.facebookPixelId);
    }
  },

  // public
  trackingEvent(event, label) {
    if (event == "immoObjectView") {
      this.gtagActions.push((gtag) => {
        gtag("event", "object-view", {
          "eventCategory": "immo",
          "eventLabel": label
        });

        if (this.debug) {
          console.log("tracking: send immo object-view, customObjectId=" + label);
        }
      });
    }

    if (event == "immoObjectInquiry") {
      this.gtagActions.push((gtag) => {
        gtag("event", "object-inquiry", {
          "eventCategory": "immo",
          "eventLabel": label
        });

        if (this.debug) {
          console.log("tracking: send immo object-inquiry, customObjectId=" + label);
        }
      });
    }

    if (event == "SavedSearchWizard") {
      this.gtagActions.push((gtag) => {
        gtag("event", "prospect-search-inquiry", {
          "eventCategory": "immo"
        });

        if (this.debug) {
          console.log("tracking: send immo prospect-search-inquiry");
        }
      });
    }

    if (event == "ContactFormWizard") {
      this.sendConversion("contact");
    }

    if (event == "LeadWizard") {
      this.sendConversion("lead");
    }

    this.flushActions();
  },

  // public
  sendConversion(group) {
    // allgemeines conversion event
    this.gtagActions.push((gtag) => {
      gtag("event", "conversion", {
        "eventCategory": group
      });

      if (this.debug) {
        console.log("tracking: conversion, group=" + group);
      }
    });

    // google ads conversion
    this.gtagActions.push((gtag) => {
      if (this.debug) {
        console.log("tracking: conversion, send_to=" + this.googleAdsConversionLabel);
      }
      gtag("event", "conversion", {"send_to": this.googleAdsConversionLabel});
    });

    const config = this.getConfig();

    // custom conversion für unser reporting
    if ("campaignTracking" in config) {
      this.gtagActions.push((gtag) => {
        if (this.debug) {
          console.log("tracking: conversion_gads_query, campaign=" + config.campaignTracking.campaign + ", keyword=" + config.campaignTracking.keyword);
        }
        gtag("event", config.campaignTracking.campaign, {
          eventCategory: "conversion_gads_query",
          eventLabel: config.campaignTracking.keyword
        });
      });
    }

    this.flushActions();
  }
};
